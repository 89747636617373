import React, {useEffect, useRef, useState} from 'react';
import {Formik} from 'formik';
import * as Yup from 'yup';
import {upsertCraneSystem} from 'api/CraneSystemsApi';
import {useAppContext} from 'contexts/AppContext';
import {isCustomerAdmin, isLocationAdmin, isSiteAdmin, isSystemAdmin} from 'utils/Auth';
import {isEmpty, isSwitchOn} from 'utils/Utils';
import CraneSystemForm from './CraneSystemForm';

const DEFAULT_INSTANCE = {
    id: '',
    crane_system_id: '',
    crane_system_name: '',
    active: ['1'],
};
const VALIDATION_RULES = {
    crane_system_id: Yup.string().required('Crane system ID is required'),
    crane_system_name: Yup.string().required('Crane system name is required'),
};

export default function CraneSystemDetails({
    craneSystem, useCustomer, useSite, useLocation, customers, sites, locations, onCloseClick
}) {
    const isMounted = useRef(false);
    const {setPageTitle, showMessage, handleApiError} = useAppContext();
    const [isLoading, setIsLoading] = useState(false);
    const [initialValues, setInitialValues] = useState({...DEFAULT_INSTANCE});
    const isEditable = isSystemAdmin() || isCustomerAdmin() || isSiteAdmin() || isLocationAdmin();
    const validationRules = {...VALIDATION_RULES};
    if (useCustomer) validationRules.customer_uuid = Yup.string().required('Customer is required');
    if (useSite) validationRules.site_uuid = Yup.string().required('Site is required');
    if (useLocation) validationRules.location_uuid = Yup.string().required('Location is required');
    const validationSchema = Yup.object().shape(validationRules);

    useEffect(() => {
        isMounted.current = true;
        setPageTitle(isEmpty(craneSystem) ? 'New crane system' : 'Crane system');
        getInitialValues();
        return () => isMounted.current = false;
    }, []);

    const getInitialValues = () => {
        if (isMounted.current) {
            setInitialValues(oldData => {
                const newData = {};
                for (const [key, value] of Object.entries(oldData)) {
                    newData[key] = craneSystem.hasOwnProperty(key) ? craneSystem[key] : value;
                }
                if (useCustomer) newData.customer_uuid = craneSystem.customer ? craneSystem.customer.customer_uuid : '';
                if (useSite) newData.site_uuid = craneSystem.site ? craneSystem.site.site_uuid : '';
                if (useLocation) newData.location_uuid = craneSystem.location ? craneSystem.location.location_uuid : '';
                newData.active = newData.active ? ['1'] : [];
                return newData;
            });
        }
    };

    const handleSubmit = (values, {setSubmitting, setErrors}) => {
        setIsLoading(true);
        const payload = {...values};
        if (!useCustomer) delete payload.customer_uuid;
        if (!useSite) delete payload.site_uuid;
        if (!useLocation) delete payload.location_uuid;
        payload.active = isSwitchOn(payload.active);
        const onError = error => handleFailureResponse(error, setErrors);
        const onDone = () => {
            if (isMounted.current) {
                setSubmitting(false);
                setIsLoading(false);
            }
        };
        upsertCraneSystem(payload, handleSuccessResponse, onError, onDone);
    };

    const handleSuccessResponse = (response) => {
        showMessage('success', 'Crane system has been successfully saved');
        onCloseClick();
    };

    const handleFailureResponse = (error, setErrors) => {
        handleApiError(error);
        if (isMounted.current && error.response) setErrors(error.response.data.errors);
    };

    return (
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
            {(props) => (
                <CraneSystemForm
                  {...props}
                  useCustomer={useCustomer}
                  useSite={useSite}
                  useLocation={useLocation}
                  customers={customers}
                  sites={sites}
                  locations={locations}
                  isEditable={isEditable}
                  isLoading={isLoading}
                  onCloseClick={onCloseClick}
                />
            )}
        </Formik>
    );
}
