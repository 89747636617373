export const isEmpty = object => {
    if (!object) return true;
    return Object.keys(object).length === 0;
};

export const formatCraneWeightFormula = (mode, a, b, c) => {
    mode = parseInt(mode);
    a = parseFloat(a);
    b = parseFloat(b);
    c = parseFloat(c);
    if (isNaN(a)) a = 0;
    if (isNaN(b)) b = 0;
    if (isNaN(c)) c = 0;
    const b_sign = b >= 0 ? '+' : '-';
    b = Math.abs(b);
    const c_sign = c >= 0 ? '+' : '-';
    c = Math.abs(c);
    let formula = '';
    if (mode === 1) formula = `y = ${a}x ${b_sign} ${b}`;
    else if (mode === 2) formula = `y = ${a}x^2 ${b_sign} ${b}x ${c_sign} ${c}`;
    return formula;
};

export const arrayToObject = (arr, attr_key, attr_value) => {
    attr_key = attr_key || 'id';
    const obj = {};
    arr.forEach(item => obj[item[attr_key]] = attr_value === undefined ? item : item[attr_value]);
    return obj;
};

export const objectToOptions = (obj, attr_value) => {
    const options = [];
    for (const [key, value] of Object.entries(obj)) {
        options.push({
            label: attr_value === undefined ? value : value[attr_value],
            value: key,
        });
    }
    return options;
};

export const convertSecondsToTimeString = seconds => {
    if (!seconds) return '00:00:00';
    const hours = Math.floor(seconds / 3600);
    seconds -= hours * 3600;
    const minutes = Math.floor(seconds / 60);
    seconds -= minutes * 60;
    const timeHours = zeroFill(hours, 2);
    const timeMinutes = zeroFill(minutes, 2);
    const timeSeconds = zeroFill(seconds, 2);
    return `${timeHours}:${timeMinutes}:${timeSeconds}`;
};

export const formatValue = value => {
    if (typeof value === 'boolean') return value.toString();
    if (typeof value === 'number') {
        if (Number.isInteger(value)) return value;
        return value.toFixed(2);
    }
    return value;
};

export const zeroFill = (num, size) => {
    let s = num + '';
    while (s.length < size) s = `0${s}`;
    return s;
}

export const isSwitchOn = value => Array.isArray(value) && value.length > 0 && parseInt(value[0]) === 1;
