import React, {useEffect, useRef, useState} from 'react';
import {Formik} from 'formik';
import * as Yup from 'yup';
import {upsertUser} from 'api/UsersApi';
import {useAppContext} from 'contexts/AppContext';
import {
    CRANE_SYSTEM_ROLES,
    CUSTOMER_AND_SUPERIOR_ROLES,
    CUSTOMER_ROLES_AND_DESCENDANTS,
    LOCATION_AND_SUPERIOR_ROLES,
    LOCATION_ROLES_AND_DESCENDANTS,
    SITE_AND_SUPERIOR_ROLES,
    SITE_ROLES_AND_DESCENDANTS,
    SYSTEM_ROLES,
    authenticatedUserId,
    isAdmin,
} from 'utils/Auth';
import {isEmpty, isSwitchOn} from 'utils/Utils';
import UserForm from './UserForm';

const DEFAULT_INSTANCE = {
    id: '',
    email: '',
    phone: '',
    username: '',
    password: '',
    role: '',
    preferences: {
        notifications: {
            estops: {email: false, sms: false},
            events: {email: false, sms: false},
            faults: {email: false, sms: false},
            maintenance_alerts: {email: false, sms: false},
            power_shutdowns: {email: false, sms: false},
        }
    },
    sms_consent: false,
    active: true,
};

const VALIDATION_RULES = {
    email: Yup.string().required('Email is required'),
    username: Yup.string().required('Name is required'),
    password: Yup.string().when(['id'], {
        is: (id) => isNaN(parseInt(id)),
        then: (schema) => schema.required('Password is required').min(6, 'Password must have at least 6 characters')
    }),
    role: Yup.string().required('Role is required'),
    sms_consent: Yup.array().of(Yup.string()).min(1, 'Your consent is required'),
};

export default function UserDetails({
    user, useCustomer, useSite, useLocation, useCraneSystem, customers, sites, locations, craneSystems, onCloseClick
}) {
    const isMounted = useRef(false);
    const {setPageTitle, showMessage, handleApiError} = useAppContext();
    const [isLoading, setIsLoading] = useState(false);
    const [initialValues, setInitialValues] = useState({...DEFAULT_INSTANCE});
    const isEditable = isAdmin();
    const validationRules = {...VALIDATION_RULES};
    if (useCustomer) {
        validationRules.customer_uuid = Yup.string().nullable().when(['role'], {
            is: (role) => CUSTOMER_ROLES_AND_DESCENDANTS.includes(parseInt(role)),
            then: (schema) => schema.required('Customer is required')
        });
    }
    if (useSite) {
        validationRules.site_uuid = Yup.string().nullable().when(['role'], {
            is: (role) => SITE_ROLES_AND_DESCENDANTS.includes(parseInt(role)),
            then: (schema) => schema.required('Site is required')
        });
    }
    if (useLocation) {
        validationRules.location_uuid = Yup.string().nullable().when(['role'], {
            is: (role) => LOCATION_ROLES_AND_DESCENDANTS.includes(parseInt(role)),
            then: (schema) => schema.required('Location is required')
        });
    }
    if (useCraneSystem) {
        validationRules.crane_system_uuid = Yup.string().nullable().when(['role'], {
            is: (role) => CRANE_SYSTEM_ROLES.includes(parseInt(role)),
            then: (schema) => schema.required('Crane system is required')
        });
    }
    const validationSchema = Yup.object().shape(validationRules);

    useEffect(() => {
        isMounted.current = true;
        setPageTitle(isEmpty(user) ? 'New user' : 'User');
        getInitialValues();
        return () => isMounted.current = false;
    }, []);

    const getInitialValues = () => {
        if (isMounted.current) {
            setInitialValues(oldData => {
                const newData = {};
                for (const [key, value] of Object.entries(oldData)) {
                    newData[key] = user.hasOwnProperty(key) ? user[key] : value;
                }
                if (useCustomer) newData.customer_uuid = user.customer ? user.customer.customer_uuid : '';
                if (useSite) newData.site_uuid = user.site ? user.site.site_uuid : '';
                if (useLocation) newData.location_uuid = user.location ? user.location.location_uuid : '';
                if (useCraneSystem) newData.crane_system_uuid = user.crane_system ? user.crane_system.crane_system_uuid : '';
                for (const value of Object.values(newData.preferences.notifications)) {
                    value.email = value.email ? ['1'] : [];
                    value.sms = value.sms ? ['1'] : [];
                }
                newData.active = newData.active ? ['1'] : [];
                newData.sms_consent = [];
                return newData;
            });
        }
    };

    const handleSubmit = (values, {setSubmitting, setErrors}) => {
        setIsLoading(true);
        const payload = JSON.parse(JSON.stringify(values));  // it's correct, we need deep copy to prevent making changes in original values
        if (payload.phone === '') payload.phone = null;
        if (payload.password === '') payload.password = null;
        payload.role = parseInt(payload.role);
        if (!useCustomer || SYSTEM_ROLES.includes(payload.role)) delete payload.customer_uuid;
        if (!useSite || CUSTOMER_AND_SUPERIOR_ROLES.includes(payload.role)) delete payload.site_uuid;
        if (!useLocation || SITE_AND_SUPERIOR_ROLES.includes(payload.role)) delete payload.location_uuid;
        if (!useCraneSystem || LOCATION_AND_SUPERIOR_ROLES.includes(payload.role)) delete payload.crane_system_uuid;
        if (payload.id && payload.id === authenticatedUserId()) payload.active = true;
        else payload.active = isSwitchOn(payload.active);
        for (const value of Object.values(payload.preferences.notifications)) {
            value.email = isSwitchOn(value.email);
            value.sms = isSwitchOn(value.sms);
        }
        const onError = error => handleFailureResponse(error, setErrors);
        const onDone = () => {
            if (isMounted.current) {
                setSubmitting(false);
                setIsLoading(false);
            }
        };
        upsertUser(payload, handleSuccessResponse, onError, onDone);
    };

    const handleSuccessResponse = response => {
        showMessage('success', 'User has been successfully saved');
        onCloseClick();
    };

    const handleFailureResponse = (error, setErrors) => {
        handleApiError(error);
        if (isMounted.current && error.response) setErrors(error.response.data.errors);
    };

    return (
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
            {(props) => (
                <UserForm
                  {...props}
                  useCustomer={useCustomer}
                  useSite={useSite}
                  useLocation={useLocation}
                  useCraneSystem={useCraneSystem}
                  customers={customers}
                  sites={sites}
                  locations={locations}
                  craneSystems={craneSystems}
                  isEditable={isEditable}
                  isLoading={isLoading}
                  onCloseClick={onCloseClick}
                />
            )}
        </Formik>
    );
}
