import React from 'react';
import {Col, Form, OverlayTrigger, Tooltip} from 'react-bootstrap';
import {useField} from 'formik';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

export default function Input({label, value, ...props}) {
    const [field, meta] = useField(props);
    const inputId = props.id || `input_${props.name}`;
    const inputValue = value !== undefined ? value : (field.value !== undefined && field.value !== null ? field.value : '');
    let inputClass = meta.touched && meta.error ? 'input error' : 'input';
    if (props.cssclass) inputClass += ' ' + props.cssclass;
    if (props.autoComplete === undefined) props.autoComplete = 'off';
    const groupProperties = {size: 'lg'};
    if (props.skipgroup === undefined) groupProperties.as = Col;
    let showError = true;
    if (props.showerror !== undefined) showError = !!props.showerror;
    return (
        <Form.Group {...groupProperties}>
            <Form.Label htmlFor={inputId}>{label}</Form.Label>
            {props.info
                ?
                <OverlayTrigger placement="top" overlay={<Tooltip>{props.info}</Tooltip>}>
                    <FontAwesomeIcon icon="fa-circle-info" size="1x" style={{marginLeft: '5px'}} />
                </OverlayTrigger>
                : null}
            <Form.Control {...field} {...props} id={inputId} value={inputValue} className={inputClass} />
            {showError && meta.touched && meta.error && <Form.Text className="text-muted text-danger">{meta.error}</Form.Text>}
        </Form.Group>
    );
}
