import React, {useEffect, useRef, useState} from 'react';
import {Formik} from 'formik';
import * as Yup from 'yup';
import {upsertMotor} from 'api/MotorsApi';
import {useAppContext} from 'contexts/AppContext';
import {isSystemAdmin} from 'utils/Auth';
import {isEmpty} from 'utils/Utils';
import MotorForm from './MotorForm';

const DEFAULT_INSTANCE = {
    id: '',
    motor_name: '',
    vfd_reference: '',
    manufacturer: '',
    part_number: '',
    motor_serial: '',
    forward_label: '',
    backward_label: '',
    horsepower: '',
    motor_speed_rated: '',
    motor_speed_ultra: '',
    motor_speed_max: '',
    motor_reduction_factor: '',
    motion_speed_unit: '',
    motor_amps_rated: '',
    motor_amps_max: '',
    base_hour_count: '',
    ts: 0,
};
const VALIDATION_SCHEMA = Yup.object().shape({
    motor_name: Yup.string().required('Name is required'),
    vfd_reference: Yup.number().required('VFD is required'),
    manufacturer: Yup.string().required('Manufacturer is required'),
    part_number: Yup.string().required('Part number is required'),
    motor_serial: Yup.string().required('Serial number is required'),
    forward_label: Yup.string().required('Forward label is required'),
    backward_label: Yup.string().required('Reverse label is required'),
    horsepower: Yup.number().required('Horsepower factor is required'),
    motor_speed_rated: Yup.number().required('Motor speed (RPM) rated is required'),
    motor_speed_max: Yup.number().required('Motor speed (RPM) max is required'),
    motor_reduction_factor: Yup.number().required('Reduction factor is required'),
    motion_speed_unit: Yup.string().required('Motion speed unit is required'),
    motor_amps_rated: Yup.number().required('Motor current (Amps) rated is required'),
    motor_amps_max: Yup.number().required('Motor current (Amps) max is required'),
    base_hour_count: Yup.number().required('Base hour count is required'),
});

export default function MotorDetails({
    pageState, currentVersion, currentConfiguration, onDataUpdate, vfds, motor, onCloseButtonClick
}) {
    const isMounted = useRef(false);
    const {showMessage, handleApiError} = useAppContext();
    const [isLoading, setIsLoading] = useState(true);
    const [initialValues, setInitialValues] = useState({...DEFAULT_INSTANCE});
    const isEditable = isSystemAdmin() && currentVersion.status === 0;

    useEffect(() => {
        isMounted.current = true;
        getInitialValues();
        return () => isMounted.current = false;
    }, [motor, pageState, currentVersion, currentConfiguration]);

    const getInitialValues = () => {
        if (isMounted.current) {
            setIsLoading(true);
            if (!isEmpty(motor)) {
                setInitialValues(oldData => {
                    const newData = {};
                    for (const [key, value] of Object.entries(oldData)) {
                        if (motor.hasOwnProperty(key)) newData[key] = motor[key];
                        else newData[key] = value;
                    }
                    newData.motor_name = motor.name;
                    newData.forward_label = motor.drive_direction.forward_label;
                    newData.backward_label = motor.drive_direction.backward_label;
                    newData.motor_speed_rated = motor.motor_in_rpm.rated;
                    newData.motor_speed_ultra = motor.motor_in_rpm.ultra_lift;
                    newData.motor_speed_max = motor.motor_in_rpm.max;
                    newData.motor_amps_rated = motor.motor_amps.rated;
                    newData.motor_amps_max = motor.motor_amps.max;
                    newData.ts = Date.now();
                    return newData;
                });
            }
            else {
                setInitialValues({
                    ...DEFAULT_INSTANCE,
                    ts: Date.now()
                });
            }
            setIsLoading(false);
        }
    };

    const handleSubmit = (values, {setSubmitting, setErrors}) => {
        setIsLoading(true);
        const driveDirection = {
            forward_label: values.forward_label,
            backward_label: values.backward_label,
        };
        const motorSpeed = {
            rated: parseFloat(values.motor_speed_rated),
            max: parseFloat(values.motor_speed_max),
        };
        if (vfds[parseInt(values.vfd_reference)].motion_type === 1) motorSpeed.ultra_lift = parseFloat(values.motor_speed_ultra) || null;
        const motorAmps = {
            rated: parseFloat(values.motor_amps_rated.toFixed(2)),
            max: parseFloat(values.motor_amps_max.toFixed(2)),
        };
        const payload = {
            id: values.id,
            motor_name: values.motor_name,
            vfd_reference: parseInt(values.vfd_reference),
            manufacturer: values.manufacturer,
            part_number: values.part_number,
            motor_serial: values.motor_serial,
            horsepower: Number(values.horsepower),
            motor_reduction_factor: parseFloat(values.motor_reduction_factor),
            motion_speed_unit: values.motion_speed_unit,
            base_hour_count: parseFloat(values.base_hour_count),
            drive_direction: driveDirection,
            motor_in_rpm: motorSpeed,
            motor_amps: motorAmps,
        };
        const onError = error => handleFailureResponse(error, setErrors);
        const onDone = () => {
            if (isMounted.current) {
                setSubmitting(false);
                setIsLoading(false);
            }
        };
        upsertMotor(pageState.craneId, payload, handleSuccessResponse, onError, onDone);
    };

    const handleSuccessResponse = (response) => {
        if (isMounted.current) {
            showMessage('success', 'Motor configuration has been successfully saved');
            onDataUpdate();
            onCloseButtonClick();
        }
    };

    const handleFailureResponse = (error, setErrors) => {
        handleApiError(error);
        if (isMounted.current && error.response) setErrors(error.response.data.errors);
    };

    return (
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={VALIDATION_SCHEMA}
          onSubmit={handleSubmit}
        >
            {(props) => (
                <MotorForm
                  {...props}
                  vfds={vfds}
                  isEditable={isEditable}
                  isLoading={isLoading}
                  currentConfiguration={currentConfiguration}
                  onCloseButtonClick={onCloseButtonClick}
                />
            )}
        </Formik>
    );
}
