import React, {useEffect, useRef, useState} from 'react';
import {Formik} from 'formik';
import * as Yup from 'yup';
import {upsertVfd} from 'api/VfdsApi';
import {craneCommunicationTypes} from 'constants/dataConstants';
import {hexRegex, ipRegex} from 'constants/uiConstants';
import {useAppContext} from 'contexts/AppContext';
import {isSystemAdmin} from 'utils/Auth';
import {isEmpty, isSwitchOn} from 'utils/Utils';
import VfdForm from './VfdForm';

const DEFAULT_INSTANCE = {
    id: '',
    vfd_name: '',
    motion_reference: '',
    motion_type: '',
    vfd_manufacturer: '',
    vfd_class: '',
    vfd_part_no: '',
    vfd_serial: '',
    current_rated: '',
    crane_communication_type: '',
    mb_address: '',
    mb_baud_rate: '',
    mb_parity: '',
    lc_mode: '',
    lc_calibration_a: '',
    lc_calibration_b: '',
    lc_calibration_c: '',
    lc_register: '',
    lc_unit: '',
    active: ['1'],
    ts: 0,
};
const VALIDATION_SCHEMA = Yup.object().shape({
    vfd_name: Yup.string().required('Name is required'),
    motion_reference: Yup.string().required('Motion is required'),
    vfd_manufacturer: Yup.string().required('Manufacturer is required'),
    vfd_class: Yup.string().required('Class is required'),
    vfd_part_no: Yup.string().required('Part number is required'),
    vfd_serial: Yup.string().required('Serial number is required'),
    current_rated: Yup.number().required('Current rated is required'),
    mb_address: Yup.string().required('Address is required')
        .when(['crane_communication_type'], {
            is: (crane_communication_type) => crane_communication_type === 'MODBUSRTU',
            then: (schema) => schema.matches(hexRegex, 'Address is not valid')
        })
        .when(['crane_communication_type'], {
            is: (crane_communication_type) => crane_communication_type === 'PLC',
            then: (schema) => schema.matches(ipRegex, 'Address is not valid')
        }),
    mb_baud_rate: Yup.number().when(['crane_communication_type'], {
        is: (crane_communication_type) => crane_communication_type === 'MODBUSRTU',
        then: (schema) => schema.required('Baud rate is required')
    }),
    mb_parity: Yup.string().when(['crane_communication_type'], {
        is: (crane_communication_type) => crane_communication_type === 'MODBUSRTU',
        then: (schema) => schema.required('Parity is required')
    }),
    lc_mode: Yup.string().when(['motion_type'], {
        is: (motion_type) => Number(motion_type) === 1,
        then: (schema) => schema.required('Mode is required')
    }),
    lc_calibration_a: Yup.number().when(['lc_mode'], {
        is: (lc_mode) => !isNaN(lc_mode),
        then: (schema) => schema.required('Factor A is required')
    }),
    lc_calibration_b: Yup.number().when(['lc_mode'], {
        is: (lc_mode) => !isNaN(lc_mode),
        then: (schema) => schema.required('Factor B is required')
    }),
    lc_calibration_c: Yup.number().when(['lc_mode'], {
        is: (lc_mode) => !isNaN(lc_mode) && Number(lc_mode) === 2,
        then: (schema) => schema.required('Factor C is required')
    }),
    lc_register: Yup.string().when(['motion_type'], {
        is: (motion_type) => Number(motion_type) === 1,
        then: (schema) => schema.required('Register is required')
    }),
    lc_unit: Yup.string().when(['motion_type'], {
        is: (motion_type) => Number(motion_type) === 1,
        then: (schema) => schema.required('Unit is required')
    }),
});

export default function VfdDetails({
    pageState, currentVersion, currentConfiguration, onDataUpdate, motions, vfd, onCloseButtonClick
}) {
    const isMounted = useRef(false);
    const {showMessage, handleApiError} = useAppContext();
    const [isLoading, setIsLoading] = useState(true);
    const [initialValues, setInitialValues] = useState({...DEFAULT_INSTANCE});
    const isEditable = isSystemAdmin() && currentVersion.status === 0;

    useEffect(() => {
        isMounted.current = true;
        getInitialValues();
        return () => isMounted.current = false;
    }, [vfd, pageState, currentVersion, currentConfiguration]);

    const getInitialValues = () => {
        if (isMounted.current) {
            setIsLoading(true);
            if (!isEmpty(vfd)) {
                setInitialValues(oldData => {
                    const newData = {};
                    for (const [key, value] of Object.entries(oldData)) {
                        if (key.startsWith('lc_')) {
                            if (key.startsWith('lc_calibration_')) {
                                let dataKey = key.substring(15);
                                if (vfd.loadcell && vfd.loadcell.calibration && vfd.loadcell.calibration.hasOwnProperty(dataKey)) newData[key] = vfd.loadcell.calibration[dataKey];
                                else newData[key] = value;
                            }
                            else {
                                let dataKey = key.substring(3);
                                if (vfd.loadcell && vfd.loadcell.hasOwnProperty(dataKey)) newData[key] = vfd.loadcell[dataKey];
                                else newData[key] = value;
                            }
                        }
                        else if (key.startsWith('mb_')) {
                            let dataKey = key.substring(3);
                            if (vfd.network && vfd.network.hasOwnProperty(dataKey)) newData[key] = vfd.network[dataKey];
                            else newData[key] = value;
                        }
                        else if (vfd.hasOwnProperty(key)) newData[key] = vfd[key];
                        else newData[key] = value;
                    }
                    newData.motion_type = motions[vfd.motion_reference].motion_type;
                    newData.crane_communication_type = craneCommunicationTypes[currentConfiguration.crane_details.crane_comm_type];
                    newData.active = newData.active ? ['1'] : [];
                    newData.ts = Date.now();
                    return newData;
                });
            }
            else {
                setInitialValues({
                    ...DEFAULT_INSTANCE,
                    crane_communication_type: !isEmpty(currentConfiguration) ? craneCommunicationTypes[currentConfiguration.crane_details.crane_comm_type] : '',
                    ts: Date.now(),
                });
            }
            setIsLoading(false);
        }
    };

    const handleSubmit = (values, {setSubmitting, setErrors}) => {
        setIsLoading(true);
        const payload = {
            id: values.id,
            vfd_name: values.vfd_name,
            motion_reference: Number(values.motion_reference),
            vfd_manufacturer: values.vfd_manufacturer,
            vfd_class: Number(values.vfd_class),
            vfd_part_no: values.vfd_part_no,
            vfd_serial: values.vfd_serial,
            current_rated: values.current_rated,
            network: {address: values.mb_address},
            active: isSwitchOn(values.active),
        };
        if (currentConfiguration.crane_details.crane_comm_type === 1) {
            payload.network.baud_rate = Number(values.mb_baud_rate);
            payload.network.parity = Number(values.mb_parity);
        }
        // hoist
        if (motions[payload.motion_reference].motion_type === 1) {
            payload.loadcell = {
                mode: Number(values.lc_mode),
                calibration: {a: parseFloat(values.lc_calibration_a), b: parseFloat(values.lc_calibration_b)},
                register: Number(values.lc_register),
                unit: values.lc_unit,
            };
            if (payload.loadcell.mode === 2) payload.loadcell.calibration.c = parseFloat(values.lc_calibration_c);
        }
        const onError = error => handleFailureResponse(error, setErrors);
        const onDone = () => {
            if (isMounted.current) {
                setSubmitting(false);
                setIsLoading(false);
            }
        };
        upsertVfd(pageState.craneId, payload, handleSuccessResponse, onError, onDone);
    };

    const handleSuccessResponse = (response) => {
        if (isMounted.current) {
            showMessage('success', 'VFD configuration has been successfully saved');
            onDataUpdate();
            onCloseButtonClick();
        }
    };

    const handleFailureResponse = (error, setErrors) => {
        handleApiError(error);
        if (isMounted.current && error.response) {
            setErrors(error.response.data.errors);
        }
    };

    return (
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={VALIDATION_SCHEMA}
          onSubmit={handleSubmit}
        >
            {(props) => (
                <VfdForm
                  {...props}
                  motions={motions}
                  isEditable={isEditable}
                  isLoading={isLoading}
                  onCloseButtonClick={onCloseButtonClick}
                />
            )}
        </Formik>
    );
}
