import React, {useEffect, useRef} from 'react';
import {Button, Form, Row} from 'react-bootstrap';
import HiddenInput from 'components/Form/HiddenInput';
import Input from 'components/Form/Input';
import Select from 'components/Form/Select';
import Switch from 'components/Form/Switch';
import {capacityUnitOptions, communicationTypeOptions} from 'constants/apiConstants';

export default function CraneConfigurationForm({values, dirty, isValid, isSubmitting, handleSubmit, isEditable, isLoading, onCloseClick}) {
    const isMounted = useRef(false);
    const isDisabled = !isEditable || isLoading;

    useEffect(() => {
        isMounted.current = true;
        return () => isMounted.current = false;
    }, []);

    return (
        <Form onSubmit={handleSubmit}>
            <Row>
                <Input label="Name" type="text" name="crane_name" required disabled={isDisabled} />
                <Select label="Communication type" name="crane_comm_type" options={communicationTypeOptions} required disabled={isDisabled} />
            </Row>
            <Row>
                <Input label="Job number" type="text" name="job_number" required disabled={isDisabled} />
                <Input label="Serial number" type="text" name="serial_number" disabled={isDisabled} />
            </Row>
            <Row>
                <Input label="Capacity" type="number" min="0" step="0.01" name="crane_capacity" required disabled={isDisabled} />
                <Select label="Capacity unit" name="capacity_unit" options={capacityUnitOptions} required disabled={isDisabled} />
            </Row>
            <Row className="mb-2">
                <Switch label="Active" id="active" name="active" value="1" disabled={isDisabled} />
            </Row>
            <HiddenInput name="id" />
            {isEditable && <Button type="submit" className="btn-form-submit" disabled={!dirty || !isValid || isSubmitting || isDisabled}>Save</Button>}
            {values.id === '' && <Button type="button" className="btn-form-close" onClick={onCloseClick} disabled={isSubmitting}>Close</Button>}
        </Form>
    )
};
