import React from 'react';
import {Col, Form} from 'react-bootstrap';
import {useField} from 'formik';

export default function Switch({label, title, value, ...props}) {
    const [field, meta] = useField(props);
    const groupStyle = props.groupstyle || {};
    const inputId = props.id || `input_${props.name}`;
    const inputValue = value !== undefined ? value : (field.value !== undefined && field.value !== null ? field.value : '');
    let inputClass = meta.touched && meta.error ? 'input error' : 'input';
    if (props.cssclass) inputClass += ' ' + props.cssclass;
    const inputChecked = Array.isArray(field.value) ? field.value.includes(value) : field.value === value;
    return (
        <Form.Group as={Col} size="lg" style={groupStyle}>
            {title && <Form.Label>{title}</Form.Label>}
            <Form.Check type="switch" {...field} {...props} id={inputId} value={inputValue} className={inputClass} label={label} checked={inputChecked} />
            {meta.touched && meta.error && <Form.Text className="text-muted text-danger">{meta.error}</Form.Text>}
        </Form.Group>
    );
}
