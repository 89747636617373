import React, {useEffect, useRef, useState} from 'react';
import {Alert, Button, Col, Form, Row} from 'react-bootstrap';
import Checkbox from 'components/Form/Checkbox';
import HiddenInput from 'components/Form/HiddenInput';
import Input from 'components/Form/Input';
import Select from 'components/Form/Select';
import Switch from 'components/Form/Switch';
import {userRoles} from 'constants/dataConstants';
import {
    DEFAULT_CRANE_SYSTEM_OPTION,
    DEFAULT_CUSTOMER_OPTION,
    DEFAULT_LOCATION_OPTION,
    DEFAULT_ROLE_OPTION,
    DEFAULT_SITE_OPTION
} from 'constants/uiConstants';
import {
    ALL_ROLES,
    CRANE_SYSTEM_ADMIN,
    CRANE_SYSTEM_ROLES,
    CRANE_SYSTEM_USER,
    CUSTOMER_ADMIN,
    CUSTOMER_ROLES_AND_DESCENDANTS,
    LOCATION_ADMIN,
    LOCATION_ROLES_AND_DESCENDANTS,
    LOCATION_USER,
    SITE_ADMIN,
    SITE_ROLES_AND_DESCENDANTS,
    SITE_USER,
    SYSTEM_ADMIN,
    SYSTEM_USER,
    authenticatedUserId,
    getUserRole,
} from 'utils/Auth';
import {getCraneSystemOptions, getCustomerOptions, getLocationOptions, getSiteOptions} from 'utils/Ui';

const switchStyle1 = {maxWidth: '200px', fontSize: '1em', fontWeight: 'bold', color: '#9a9a9a'};
const switchStyle2 = {maxWidth: '130px'};

export default function UserForm({
    initialValues, values, dirty, isValid, isSubmitting, setFieldValue, handleChange, handleSubmit,
    useCustomer, useSite, useLocation, useCraneSystem, customers, sites, locations, craneSystems,
    isEditable, isLoading, onCloseClick, ...props
}) {
    const isMounted = useRef(false);
    const [roleOptions, setRoleOptions] = useState([DEFAULT_ROLE_OPTION]);
    const [customerOptions, setCustomerOptions] = useState([DEFAULT_CUSTOMER_OPTION]);
    const [siteOptions, setSiteOptions] = useState([DEFAULT_SITE_OPTION]);
    const [locationOptions, setLocationOptions] = useState([DEFAULT_LOCATION_OPTION]);
    const [craneSystemOptions, setCraneSystemOptions] = useState([DEFAULT_CRANE_SYSTEM_OPTION]);
    const isDisabled = !isEditable || isLoading;
    const showActive = !values.id || values.id !== authenticatedUserId();
    const showCustomer = useCustomer && CUSTOMER_ROLES_AND_DESCENDANTS.includes(Number(values.role));
    const showSite = useSite && SITE_ROLES_AND_DESCENDANTS.includes(Number(values.role));
    const showLocation = useLocation && LOCATION_ROLES_AND_DESCENDANTS.includes(Number(values.role));
    const showCraneSystem = useCraneSystem && CRANE_SYSTEM_ROLES.includes(Number(values.role));

    useEffect(() => {
        isMounted.current = true;
        let options;
        getRoleOptions();
        if (useCustomer) setCustomerOptions(getCustomerOptions(customers));
        if (useSite) {
            options = getSiteOptions(
                sites, useCustomer, initialValues.customer_uuid,
                false, setFieldValue
            );
            setSiteOptions(options);
        }
        if (useLocation) {
            options = getLocationOptions(
                locations, useSite, initialValues.site_uuid,
                false, setFieldValue
            );
            setLocationOptions(options);
        }
        if (useCraneSystem) {
            options = getCraneSystemOptions(
                craneSystems, useLocation, initialValues.location_uuid,
                false, setFieldValue
            );
            setCraneSystemOptions(options);
        }
        return () => isMounted.current = false;
    }, [initialValues, customers, sites, locations, craneSystems]);

    const getRoleOptions = () => {
        const userRole = getUserRole();
        let roleIds;
        switch (userRole) {
            case SYSTEM_ADMIN:
                roleIds = ALL_ROLES; break;
            case SYSTEM_USER:
                roleIds = ALL_ROLES; break;
            case CUSTOMER_ADMIN:
                roleIds = CUSTOMER_ROLES_AND_DESCENDANTS; break;
            case SITE_ADMIN:
                roleIds = [SITE_USER, LOCATION_USER, CRANE_SYSTEM_USER]; break;
            case LOCATION_ADMIN:
                roleIds = [LOCATION_USER, CRANE_SYSTEM_USER]; break;
            case CRANE_SYSTEM_ADMIN:
                roleIds = [CRANE_SYSTEM_USER]; break;
        }
        if (isMounted.current) {
            setRoleOptions([
                DEFAULT_ROLE_OPTION,
                ...roleIds.map(roleId => ({label: userRoles[roleId], value: roleId}))
            ]);
        }
    };

    const handleCustomerChange = e => {
        handleChange(e);
        setSiteOptions(getSiteOptions(sites, useCustomer, e.target.value, true, setFieldValue));
        setLocationOptions(getLocationOptions(locations, useSite, '', true, setFieldValue));
        setCraneSystemOptions(getCraneSystemOptions(craneSystems, useLocation, '', true, setFieldValue));
    };

    const handleSiteChange = e => {
        handleChange(e);
        setLocationOptions(getLocationOptions(locations, useSite, e.target.value, true, setFieldValue));
        setCraneSystemOptions(getCraneSystemOptions(craneSystems, useLocation, '', true, setFieldValue));
    };

    const handleLocationChange = e => {
        handleChange(e);
        setCraneSystemOptions(getCraneSystemOptions(craneSystems, useLocation, e.target.value, true, setFieldValue));
    };

    return (
        <Form onSubmit={handleSubmit}>
            {showActive &&
                <Row>
                    <Switch label="Active" title="Account state" name="active" value="1" disabled={isDisabled} />
                </Row>}
            <Row>
                <Input label="Name" type="text" name="username" required disabled={isDisabled} />
            </Row>
            <Row>
                <Input label="Email" type="email" name="email" required disabled={isDisabled} />
            </Row>
            <Row>
                <Input label="Phone number" type="text" name="phone" disabled={isDisabled} />
            </Row>
            <Row>
                <Select label="Role" name="role" options={roleOptions} required disabled={isDisabled} />
            </Row>
            <Row>
                <Input
                  label="Password" type="password" name="password"
                  info={!isNaN(Number(initialValues.id)) ? 'Enter password only if you want to change it' : null}
                  required={isNaN(Number(initialValues.id))}
                  disabled={isDisabled}
                />
            </Row>
            {showCustomer &&
                <Row>
                    <Select
                      label="Customer" name="customer_uuid"
                      options={customerOptions} onChange={handleCustomerChange}
                      required disabled={isDisabled}
                    />
                </Row>}
            {showSite &&
                <Row>
                    <Select
                      label="Site" name="site_uuid"
                      options={siteOptions} onChange={handleSiteChange}
                      required disabled={isDisabled}
                    />
                </Row>}
            {showLocation &&
                <Row>
                    <Select
                      label="Location" name="location_uuid"
                      options={locationOptions} onChange={handleLocationChange}
                      required disabled={isDisabled}
                    />
                </Row>}
            {showCraneSystem &&
                <Row>
                    <Select
                      label="Crane system" name="crane_system_uuid"
                      options={craneSystemOptions}
                      required disabled={isDisabled}
                    />
                </Row>}
            <Row><Form.Group as={Col} size="lg"><Form.Label>Notification preferences</Form.Label></Form.Group></Row>
            <Row>
                <Form.Group as={Col} size="lg" style={switchStyle1}>Edge power loss</Form.Group>
                <Switch name="preferences.notifications.power_shutdowns.email" label="Email" value="1" groupstyle={switchStyle2} disabled={isDisabled} />
                <Switch name="preferences.notifications.power_shutdowns.sms" label="SMS" value="1" groupstyle={switchStyle2} disabled={isDisabled} />
            </Row>
            <Row>
                <Form.Group as={Col} size="lg" style={switchStyle1}>Emergency stop</Form.Group>
                <Switch name="preferences.notifications.estops.email" label="Email" value="1" groupstyle={switchStyle2} disabled={isDisabled} />
                <Switch name="preferences.notifications.estops.sms" label="SMS" value="1" groupstyle={switchStyle2} disabled={isDisabled} />
            </Row>
            <Row>
                <Form.Group as={Col} size="lg" style={switchStyle1}>Event notifications</Form.Group>
                <Switch name="preferences.notifications.events.email" label="Email" value="1" groupstyle={switchStyle2} disabled={isDisabled} />
                <Switch name="preferences.notifications.events.sms" label="SMS" value="1" groupstyle={switchStyle2} disabled={isDisabled} />
            </Row>
            <Row>
                <Form.Group as={Col} size="lg" style={switchStyle1}>Faults notifications</Form.Group>
                <Switch name="preferences.notifications.faults.email" label="Email" value="1" groupstyle={switchStyle2} disabled={isDisabled} />
                <Switch name="preferences.notifications.faults.sms" label="SMS" value="1" groupstyle={switchStyle2} disabled={isDisabled} />
            </Row>
            <Row>
                <Form.Group as={Col} size="lg" style={switchStyle1}>Maintenance alerts</Form.Group>
                <Switch name="preferences.notifications.maintenance_alerts.email" label="Email" value="1" groupstyle={switchStyle2} disabled={isDisabled} />
                <Switch name="preferences.notifications.maintenance_alerts.sms" label="SMS" value="1" groupstyle={switchStyle2} disabled={isDisabled} />
            </Row>
            <HiddenInput name="id" />
            <Alert variant="secondary">
                <div className="mb-2">
                    To manage your communication preferences, please note that you may opt out of receiving SMS text messages at any time.
                    To do so, please access the configuration section of the cloud UI within your account and update your settings directly.
                    Opt-out requests for SMS notifications cannot be processed through other channels and must be completed on this platform.
                </div>
                <div>
                    <b>Note:</b> Customers configuring the Smart Crane System SMS notifications on behalf of their employees are responsible
                    for conveying this information accordingly.
                </div>
            </Alert>
            {isEditable &&
                <>
                    <div>
                        <Checkbox
                          id="sms_consent"
                          name="sms_consent"
                          value="1"
                          label="By checking this box, I acknowledge I have read and understood, and agree to the above statement."
                          disabled={isDisabled}
                        />
                    </div>
                    <Button type="submit" className="btn-form-submit" disabled={!dirty || !isValid || isSubmitting || isDisabled}>Save</Button>
                </>}
            <Button type="button" className="btn-form-close" onClick={onCloseClick} disabled={isSubmitting}>Close</Button>
        </Form>
    )
}
