import React, {useEffect, useRef, useState} from 'react';
import {Formik} from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import {upsertMotion} from 'api/MotionsApi';
import {DATE_FORMAT_ISO, TIME_FORMAT_SHORT} from 'constants/uiConstants';
import {useAppContext} from 'contexts/AppContext';
import {isSystemAdmin} from 'utils/Auth';
import {isEmpty} from 'utils/Utils';
import MotionForm from './MotionForm';

const DEFAULT_INSTANCE = {
    id: '',
    motion_name: '',
    motion_type: '',
    motion_capacity: '',
    motion_reference: '',
    start_date: '',
    start_time: '',
    ts: 0,
};
const VALIDATION_SCHEMA = Yup.object().shape({
    motion_name: Yup.string().required('Motion name is required'),
    motion_type: Yup.string().required('Motion type is required'),
    motion_capacity: Yup.number().required('Motion capacity is required'),
    start_date: Yup.string().required('Odometer data start date is required'),
    start_time: Yup.string().required('Odometer data start time is required'),
});

export default function MotionDetails({
    pageState, currentVersion, currentConfiguration, onDataUpdate, motionId, onCloseButtonClick
}) {
    const isMounted = useRef(false);
    const {showMessage, handleApiError} = useAppContext();
    const [isLoading, setIsLoading] = useState(true);
    const [initialValues, setInitialValues] = useState({...DEFAULT_INSTANCE});
    const isEditable = isSystemAdmin() && currentVersion.status === 0;

    useEffect(() => {
        isMounted.current = true;
        getInitialValues();
        return () => isMounted.current = false;
    }, [motionId, pageState, currentVersion, currentConfiguration]);

    const getInitialValues = () => {
        if (isMounted.current) {
            setIsLoading(true);
            if (
                motionId &&
                !isEmpty(currentConfiguration) &&
                !isEmpty(currentConfiguration.crane_details) &&
                !isEmpty(currentConfiguration.crane_details.motion_config)
            ) {
                currentConfiguration.crane_details.motion_config.forEach(motion => {
                    if (motion.id === motionId) {
                        setInitialValues(oldData => {
                            const newData = {};
                            for (const [key, value] of Object.entries(oldData)) {
                                newData[key] = motion.hasOwnProperty(key) ? motion[key] : value;
                            }
                            if (motion.odometer_data_start_date) {
                                const dateObj = moment(motion.odometer_data_start_date);
                                newData.start_date = dateObj.format(DATE_FORMAT_ISO);
                                newData.start_time = dateObj.format(TIME_FORMAT_SHORT);
                            }
                            newData.ts = Date.now();
                            return newData;
                        });
                    }
                });
            }
            else {
                setInitialValues({...DEFAULT_INSTANCE, ts: Date.now()});
            }
            setIsLoading(false);
        }
    };

    const handleSubmit = (values, {setSubmitting, setErrors}) => {
        setIsLoading(true);
        const payload = {
            id: values.id,
            motion_name: values.motion_name,
            motion_type: parseInt(values.motion_type),
            motion_capacity: Number(values.motion_capacity),
            motion_reference: values.motion_reference ? parseInt(values.motion_reference) : null,
            odometer_data_start_date: moment(values.start_date + ' ' + values.start_time).utc().toISOString(),
        };
        const onError = error => handleFailureResponse(error, setErrors);
        const onDone = () => {
            if (isMounted.current) {
                setSubmitting(false);
                setIsLoading(false);
            }
        };
        upsertMotion(pageState.craneId, payload, handleSuccessResponse, onError, onDone);
    };

    const handleSuccessResponse = response => {
        if (isMounted.current) {
            showMessage('success', 'Motion configuration has been successfully saved');
            onDataUpdate();
            onCloseButtonClick();
        }
    };

    const handleFailureResponse = (error, setErrors) => {
        handleApiError(error);
        if (isMounted.current && error.response) setErrors(error.response.data.errors);
    };

    return (
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={VALIDATION_SCHEMA}
          onSubmit={handleSubmit}
        >
            {(props) => (
                <MotionForm
                  {...props}
                  isEditable={isEditable}
                  isLoading={isLoading}
                  currentConfiguration={currentConfiguration}
                  onCloseButtonClick={onCloseButtonClick}
                />
            )}
        </Formik>
    );
}
