import React, {useEffect, useState} from 'react';
import {Alert, Col, Row, Spinner} from 'react-bootstrap';
import moment from 'moment';
import {DATE_FORMAT_ISO} from 'constants/uiConstants';
import {arrayToObject} from 'utils/Utils';
import Chart from './Chart';
import ChartDataForm from './ChartDataForm';
import ChartParametersForm from './ChartParametersForm';

const alertStyle = {
    marginTop: '20px',
    color: 'black',
    fontWeight: 'bold',
};
const spinnerStyle = {
    marginTop: '20px',
    marginBottom: '20px',
};

export default function CraneCharts({crane, motionsList, motionParameters}) {
    const [isLoading, setIsLoading] = useState(false);
    const [chartData, setChartData] = useState([]);
    const [chartDates, setChartDates] = useState([null, null]);
    const [parametersMap, setParametersMap] = useState({});
    const [selectedParameters, setSelectedParameters] = useState(['run_time_cumulative', 'current_avg']);

    useEffect(() => {
        setParametersMap(arrayToObject(motionParameters, 'name', 'display'));
    }, [motionParameters]);

    let content;
    if (isLoading) content = <div className="text-center" style={spinnerStyle}><Spinner animation="border" /></div>;
    else if (chartData.length === 0) content = <Alert variant="dark" style={alertStyle}>No data</Alert>;
    else content = (
        <Row>
            <Col>
                <Chart
                  chartData={chartData}
                  chartDates={chartDates}
                  parametersMap={parametersMap}
                  selectedParameters={selectedParameters}
                />
            </Col>
        </Row>
    );

    return (
        <div className="content">
            <Row>
                <Col className="col-header">
                    <span className="text-title title-header">Charts</span>
                </Col>
            </Row>
            <Row>
                <Col>
                    <ChartDataForm
                      crane={crane}
                      motionsList={motionsList}
                      setIsLoading={setIsLoading}
                      setChartData={setChartData}
                      setChartDates={setChartDates}
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <ChartParametersForm
                      parametersMap={parametersMap}
                      selectedParameters={selectedParameters}
                      setSelectedParameters={setSelectedParameters}
                    />
                </Col>
            </Row>
            {content}
        </div>
    );
};
