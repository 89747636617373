import React, {useEffect, useRef, useState} from 'react';
import {Button, Form, Row} from 'react-bootstrap';
import {motionTypeOptions} from 'constants/apiConstants';
import {capacityUnits} from 'constants/dataConstants';
import {isEmpty} from 'utils/Utils';
import HiddenInput from 'components/Form/HiddenInput';
import Input from 'components/Form/Input';
import Select from 'components/Form/Select';

const DEFAULT_MOTION_PARENT_OPTION = {label: '-- Select motion --', value: ''};

export default function MotionForm({
    values, dirty, isValid, isSubmitting, handleSubmit,
    isEditable, isLoading, currentConfiguration, onCloseButtonClick
}) {
    const isMounted= useRef(false);
    const [motionParentOptions, setMotionParentOptions] = useState([DEFAULT_MOTION_PARENT_OPTION]);
    const isDisabled = !isEditable || isLoading;

    useEffect(() => {
        isMounted.current = true;
        getMotionParentOptions();
        return () => isMounted.current = false;
    }, [currentConfiguration, values]);

    const getMotionParentOptions = () => {
        const options = [];
        if (!isEmpty(currentConfiguration) && !isEmpty(currentConfiguration.crane_details)) {
            currentConfiguration.crane_details.motion_config.forEach(motion => {
                if (motion.id !== values.id) options.push({label: motion.motion_name, value: motion.id});
            });
        }
        setMotionParentOptions([DEFAULT_MOTION_PARENT_OPTION, ...options]);
    };

    const motionCapacityUnit = !isEmpty(currentConfiguration) ? capacityUnits[currentConfiguration.crane_details.capacity_unit] : '';

    return (
        <Form onSubmit={handleSubmit}>
            <Row>
                <Input label="Name" type="text" name="motion_name" required disabled={isDisabled} />
            </Row>
            <Row>
                <Select label="Type" name="motion_type" options={motionTypeOptions} required disabled={isDisabled} />
                <Select label="Installed on" name="motion_reference" options={motionParentOptions} disabled={isDisabled} />
            </Row>
            <Row>
                <Input label="Capacity" type="number" min="0" step="0.01" name="motion_capacity" required disabled={isDisabled} />
                <Input label="Unit" type="text" name="motion_capacity_unit" value={motionCapacityUnit} disabled />
            </Row>
            <Row>
                <Input label="Odometer data start date" type="date" name="start_date" required disabled={isDisabled} />
                <Input label="Start time" type="time" name="start_time" required disabled={isDisabled} />
            </Row>
            <HiddenInput name="id" />
            <HiddenInput name="ts" />
            {isEditable && <Button type="submit" className="btn-form-submit" disabled={!dirty || !isValid || isSubmitting || isDisabled}>Save</Button>}
            <Button type="button" className="btn-form-close" onClick={onCloseButtonClick} disabled={isSubmitting}>Close</Button>
        </Form>
    )
};
