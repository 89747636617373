import React, {useEffect, useRef, useState} from 'react';
import {Button, Form} from 'react-bootstrap';
import {Formik} from 'formik';
import moment from 'moment';
import * as Yup from 'yup';
import {getCraneChartData} from 'api/CranesApi';
import Input from 'components/Form/Input';
import Select from 'components/Form/Select';
import {DATE_FORMAT_ISO} from 'constants/uiConstants';
import {useAppContext} from 'contexts/AppContext';
import {arrayToObject, objectToOptions} from 'utils/Utils';

const filterStyle = {display: 'inline-block', marginRight: 10};

const timeRanges = {
    hours_24: ['24 hours', 'hours', 24],
    days_3: ['3 days', 'days', 3],
    days_7: ['7 days', 'days', 7],
    days_30: ['30 days', 'days', 30],
    days_90: ['90 days', 'days', 90],
    days_180: ['180 days', 'days', 180],
    year_1: ['1 year', 'years', 1],
    custom: ['Custom', null, null],
};
const timeRangeOptions = objectToOptions(timeRanges, 0);
const MIN_DATE = '2024-01-01';
const MAX_RANGE = ['years', 1];

export default function ChartDataForm({crane, motionsList, setIsLoading, setChartData, setChartDates}) {
    const isMounted = useRef(false);
    const {handleApiError} = useAppContext();
    const [motionOptions, setMotionOptions] = useState([]);
    const [motion, setMotion] = useState('');
    const [timeRange, setTimeRange] = useState('hours_24');
    const [timeRangeShift, setTimeRangeShift] = useState(1);
    const [customDateFrom, setCustomDateFrom] = useState('');
    const [customDateTo, setCustomDateTo] = useState('');
    const [minDateFrom, setMinDateFrom] = useState(MIN_DATE);
    const [maxDateFrom, setMaxDateFrom] = useState(moment().format(DATE_FORMAT_ISO));
    const [minDateTo, setMinDateTo] = useState(MIN_DATE);
    const [maxDateTo, setMaxDateTo] = useState(moment().format(DATE_FORMAT_ISO));

    useEffect(() => {
        isMounted.current = true;
        return () => isMounted.current = false;
    }, []);

    useEffect(() => {
        setMotion(motionsList.length ? motionsList[0].motion_uuid : '');
        setMotionOptions(
            objectToOptions(
                arrayToObject(motionsList, 'motion_uuid', 'motion_name')
            )
        );
    }, [motionsList]);

    useEffect(() => {
        loadCraneChartData();
    }, [motion, timeRange, timeRangeShift]);

    const initialValues = {
        motion: motion,
        time_range: timeRange,
        date_from: customDateFrom,
        date_to: customDateTo,
    };

    const validationSchema = Yup.object().shape({
        motion: Yup.string().required(),
        time_range: Yup.string().required(),
        date_from: Yup.date().min(minDateFrom).max(maxDateFrom).required(),
        date_to: Yup.date().min(minDateTo).max(maxDateTo).required(),
    });

    const timeRangeToDates = () => {
        const now = moment();
        now.startOf('hour');
        let dateFrom;
        let dateTo;
        if (timeRange === 'custom') {
            dateFrom = customDateFrom && moment(customDateFrom);
            dateTo = customDateTo && moment(customDateTo);
            if (dateFrom) dateFrom.startOf('day');
            if (dateTo) {
                dateTo.startOf('day').add(1, 'days');
                dateTo = moment.min(dateTo, now);
            }
        }
        else {
            dateFrom = now.clone();
            dateFrom.subtract(timeRanges[timeRange][2] * timeRangeShift, timeRanges[timeRange][1]);
            if (timeRanges[timeRange][1] !== 'hours') dateFrom.startOf('day');
            dateTo = now.clone();
            if (timeRangeShift > 1) {
                if (timeRanges[timeRange][1] !== 'hours') dateTo.startOf('day');
                dateTo.subtract(timeRanges[timeRange][2] * (timeRangeShift - 1), timeRanges[timeRange][1]);
            }
        }
        return [dateFrom, dateTo];
    };

    const loadCraneChartData = () => {
        if (motion === '') return;
        const [dateFrom, dateTo] = timeRangeToDates();
        if (!dateFrom || !dateTo) return;
        setIsLoading(true);
        const payload = {
            motion_uuid: motion,
            date_from: dateFrom.toISOString(),
            date_to: dateTo.toISOString(),
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        }
        const onSuccess = response => {
            setChartData(response.data);
            setChartDates([dateFrom, dateTo]);
        };
        const onDone = () => isMounted.current && setIsLoading(false);
        getCraneChartData(crane.id, payload, onSuccess, handleApiError, onDone);
    };

    return (
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={() => {}}
        >
            {({isValid, isSubmitting, handleChange, handleSubmit}) => {

                const [showCustomFields, setShowCustomFields] = useState(false);

                const handleMotionChange = e => {
                    handleChange(e);
                    setMotion(e.target.value);
                };

                const handleTimeRangeChange = e => {
                    handleChange(e);
                    setTimeRangeShift(1);
                    setTimeRange(e.target.value);
                    setShowCustomFields(e.target.value === 'custom');
                }

                const handleDateFromChange = e => {
                    handleChange(e);
                    setCustomDateFrom(e.target.value);
                    let rangeMin = moment(MIN_DATE);
                    let rangeMax = moment();
                    const selectedDate = e.target.value && moment(e.target.value);
                    if (selectedDate) {
                        rangeMin = selectedDate;
                        rangeMax = selectedDate.clone();
                        rangeMax = rangeMax.add(MAX_RANGE[1], MAX_RANGE[0]);
                        rangeMax = moment.min(rangeMax, moment());
                    }
                    setMinDateTo(rangeMin.format(DATE_FORMAT_ISO));
                    setMaxDateTo(rangeMax.format(DATE_FORMAT_ISO));
                };

                const handleDateToChange = e => {
                    handleChange(e);
                    setCustomDateTo(e.target.value);
                    let rangeMin = moment(MIN_DATE);
                    let rangeMax = moment();
                    const selectedDate = e.target.value && moment(e.target.value);
                    if (selectedDate) {
                        rangeMin = selectedDate.clone();
                        rangeMin.subtract(MAX_RANGE[1], MAX_RANGE[0]);
                        rangeMin = moment.max(rangeMin, moment(MIN_DATE));
                        rangeMax = selectedDate;
                    }
                    setMinDateFrom(rangeMin.format(DATE_FORMAT_ISO));
                    setMaxDateFrom(rangeMax.format(DATE_FORMAT_ISO));
                };

                const handleApplyButtonClick = e => {
                    loadCraneChartData();
                };

                const handlePrevButtonClick = e => {
                    setTimeRangeShift(oldValue => oldValue + 1);
                };

                const handleNextButtonClick = e => {
                    setTimeRangeShift(oldValue => Math.max(oldValue - 1, 1));
                };

                return (
                    <Form onSubmit={handleSubmit}>
                        <div style={filterStyle}>
                            <Select
                              label="Motion" name="motion" options={motionOptions} skipgroup="true"
                              onChange={handleMotionChange} required disabled={isSubmitting}
                            />
                        </div>
                        <div style={filterStyle}>
                            <Select
                              label="Time range" name="time_range" options={timeRangeOptions} skipgroup="true"
                              onChange={handleTimeRangeChange} required disabled={isSubmitting}
                            />
                        </div>
                        {showCustomFields &&
                            <>
                                <div style={filterStyle}>
                                    <Input
                                      label="From" type="date" name="date_from" min={minDateFrom} max={maxDateFrom}
                                      skipgroup="true" showerror={0} onChange={handleDateFromChange} required disabled={isSubmitting}
                                    />
                                </div>
                                <div style={filterStyle}>
                                    <Input
                                      label="To" type="date" name="date_to" min={minDateTo} max={maxDateTo}
                                      skipgroup="true" showerror={0} onChange={handleDateToChange} required disabled={isSubmitting}
                                    />
                                </div>
                                <div style={filterStyle}>
                                    <Button variant="primary" onClick={handleApplyButtonClick} disabled={!isValid}>Apply</Button>
                                </div>
                            </>}
                        {!showCustomFields &&
                            <>
                                <div style={filterStyle}><Button variant="primary" onClick={handlePrevButtonClick}>&lt;&lt;</Button></div>
                                <div style={filterStyle}><Button variant="primary" onClick={handleNextButtonClick} disabled={timeRangeShift <= 1}>&gt;&gt;</Button></div>
                            </>}
                    </Form>
                );
            }}
        </Formik>
    );
};
