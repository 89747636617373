import React from 'react';
import {CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis} from 'recharts';
import moment from 'moment';
import {DATETIME_SHORT_FORMAT} from 'constants/uiConstants';
import {convertSecondsToTimeString} from 'utils/Utils';

const colors = {
    motor_in_rpm_avg: '#ff883e',
    motor_in_rpm_max: '#7D3C98',
    run_time_cumulative: '#003082',
    run_time_hourly: '#004298',
    current_avg: '#1E8449',
    current_max: '#001234',
    loadcell_avg: '#F1DC23',
    loadcell_max: '#F1AF23',
    number_of_start_stop_cumulative: '#841E6B',
    number_of_start_stop_hourly: '#867E6B',
};

const formatYAxis = (parameter, value) => {
    if (parameter.startsWith('run_time')) return convertSecondsToTimeString(value);
    return value;
};

const formatTooltip = (value, name, props) => {
    if (name.startsWith('Run Time')) value = convertSecondsToTimeString(value);
    return [value, name];
};

export default function Chart({chartData, chartDates, parametersMap, selectedParameters}) {
    const startTimestamp = chartDates[0] && chartDates[0].valueOf() || moment();
    const endTimestamp = chartDates[1] && chartDates[1].valueOf() || moment();
    const dataList = [];
    for (const [timestamp, datapoint] of Object.entries(chartData)) {
        const data = {timestamp: moment(timestamp).valueOf()};
        selectedParameters.forEach(selectedParameter => {
            if (Object.hasOwn(datapoint, selectedParameter)) data[selectedParameter] = datapoint[selectedParameter];
        });
        dataList.push(data);
    }
    const yAxes = {
        left: {parameters: [], label: null, color: null, offset: 0},
        right: {parameters: [], label: null, color: null, offset: 0},
    };
    const parameterToAxis = {};
    selectedParameters.forEach(selectedParameter => {
        parameterToAxis[selectedParameter] = 'hidden';
        for (const [yAxisId, yAxisProps] of Object.entries(yAxes)) {
            if (yAxisProps.parameters.length === 0) {
                yAxisProps.parameters.push(selectedParameter);
                yAxisProps.label = parametersMap[selectedParameter];
                yAxisProps.color = colors[selectedParameter];
                if (selectedParameter.startsWith('run_time')) yAxisProps.offset = -15;
                parameterToAxis[selectedParameter] = yAxisId;
                break;
            }
            if (
                ['motor_in_rpm', 'current', 'loadcell'].includes(selectedParameter.slice(0, -4)) &&
                yAxisProps.parameters[0].slice(0, -4) === selectedParameter.slice(0, -4)
            ) {
                yAxisProps.parameters.push(selectedParameter);
                yAxisProps.label = yAxisProps.label.slice(0, -6);
                parameterToAxis[selectedParameter] = yAxisId;
                break;
            }
        }
    });
    const xAxisTicks = [startTimestamp, endTimestamp];

    return (
        <ResponsiveContainer width="100%" height={500}>
            <LineChart data={dataList} margin={{top: 5, right: 30, left: 20, bottom: 5}}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey="timestamp"
                  domain={[startTimestamp, endTimestamp]}
                  name="Time"
                  tickFormatter={unixTime => moment.unix(unixTime / 1000).format(DATETIME_SHORT_FORMAT)}
                  ticks={xAxisTicks}
                  type="number"
                  interval="preserveStartEnd"
                  allowDataOverflow={false}
                  axisLine={false}
                  tickLabelAngle={-90}
                />
                {yAxes.left.parameters.length &&
                    <YAxis
                      yAxisId="left"
                      tick={{fill: yAxes.left.color}}
                      tickFormatter={value => formatYAxis(yAxes.left.parameters[0], value)}
                      label={{value: yAxes.left.label, angle: -90, position: "insideLeft", offset: yAxes.left.offset}}
                    />}
                {yAxes.right.parameters.length &&
                    <YAxis
                      yAxisId="right"
                      tick={{fill: yAxes.right.color}}
                      tickFormatter={value => formatYAxis(yAxes.right.parameters[0], value)}
                      orientation="right"
                      label={{value: yAxes.right.label, angle: -270, position: "insideRight", offset: yAxes.right.offset}}
                    />}
                <YAxis yAxisId="hidden" hide />
                <Tooltip
                  labelFormatter={unixTime => moment.unix(unixTime / 1000).format(DATETIME_SHORT_FORMAT)}
                  formatter={formatTooltip}
                />
                <Legend verticalAlign="top" height={50} />
                {selectedParameters.map(parameter =>
                    <Line
                      key={parameter}
                      name={parametersMap[parameter]}
                      type="monotone"
                      dataKey={parameter}
                      stroke={colors[parameter]}
                      fill={colors[parameter]}
                      yAxisId={parameterToAxis[parameter]}
                      isAnimationActive={false}
                    />
                )}
            </LineChart>
        </ResponsiveContainer>
    );
};
