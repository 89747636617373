import React, {useEffect, useRef} from 'react';
import {Button, Form, Row} from 'react-bootstrap';
import HiddenInput from 'components/Form/HiddenInput';
import Input from 'components/Form/Input';
import Select from 'components/Form/Select';

const DEFAULT_VFD_OPTION = {label: '-- Select VFD --', value: ''};

export default function MotorForm({
    values, dirty, isValid, isSubmitting, handleSubmit,
    vfds, isEditable, isLoading, onCloseButtonClick
}) {
    const isMounted= useRef(false);
    const isDisabled = !isEditable || isLoading;
    const vfdOptions = [DEFAULT_VFD_OPTION, ...Object.values(vfds).map(vfd => ({label: vfd.vfd_name, value: vfd.id}))];

    useEffect(() => {
        isMounted.current = true;
        return () => isMounted.current = false;
    }, []);

    const calculateMotionValue = (speed, reductionFactor) => {
        speed = parseFloat(speed);
        reductionFactor = parseFloat(reductionFactor);
        if (isNaN(speed) || isNaN(reductionFactor) || reductionFactor === 0.0) return '';
        return Math.round(speed / reductionFactor);
    };

    const motionSpeedRated = calculateMotionValue(values.motor_speed_rated, values.motor_reduction_factor);
    const motionSpeedUltra = calculateMotionValue(values.motor_speed_ultra, values.motor_reduction_factor);
    const motionSpeedMax = calculateMotionValue(values.motor_speed_max, values.motor_reduction_factor);
    const motionSpeedUnit = values.motion_speed_unit && ` (${values.motion_speed_unit})` || '';
    const motionSpeedLabel1 = `Motion speed${motionSpeedUnit} - rated`;
    const motionSpeedLabel2 = `Motion speed${motionSpeedUnit} - ultra lift`;
    const motionSpeedLabel3 = `Motion speed${motionSpeedUnit} - max`;
    const vfd = values.vfd_reference && vfds[parseInt(values.vfd_reference)];
    const showUltra = vfd && vfd.motion_type === 1;

    return (
        <Form onSubmit={handleSubmit}>
            <Row>
                <Input label="Name" type="text" name="motor_name" required disabled={isDisabled} />
                <Select label="VFD" name="vfd_reference" options={vfdOptions} required disabled={isDisabled} />
            </Row>
            <Row>
                <Input label="Manufacturer" type="text" name="manufacturer" required disabled={isDisabled} />
                <Input label="Part number" type="text" name="part_number" required disabled={isDisabled} />
                <Input label="Serial number" type="text" name="motor_serial" required disabled={isDisabled} />
            </Row>
            <Row>
                <Input label="Drive direction - forward label" type="text" name="forward_label" required disabled={isDisabled} />
                <Input label="Reverse label" type="text" name="backward_label" required disabled={isDisabled} />
            </Row>
            <Row>
                <Input label="Horsepower" type="number" min="0" step="0.01" name="horsepower" required disabled={isDisabled} />
                <Input label="Motor current (Amps) - rated" type="number" min="0" step="0.01" name="motor_amps_rated" required disabled={isDisabled} />
                <Input label="Motor current (Amps) - max" type="number" min="0" step="0.01" name="motor_amps_max" required disabled={isDisabled} />
            </Row>
            <Row>
                <Input label="Motor speed (RPM) - rated" type="number" name="motor_speed_rated" required disabled={isDisabled} />
                {showUltra && <Input label="Motor speed (RPM) - ultra lift" type="number" name="motor_speed_ultra" disabled={isDisabled} />}
                <Input label="Motor speed (RPM) - max" type="number" name="motor_speed_max" required disabled={isDisabled} />
            </Row>
            <Row>
                <Input
                  label="Reduction factor" type="number" name="motor_reduction_factor"
                  info="This field is used to enter a value that mathematically correlates motor rpm to motion speed"
                  required disabled={isDisabled}
                />
                <Input label="Motion speed unit" type="text" name="motion_speed_unit" required disabled={isDisabled} />
            </Row>
            <Row>
                <Input label={motionSpeedLabel1} type="number" name="motion_speed_rated" value={motionSpeedRated} disabled />
                {showUltra && <Input label={motionSpeedLabel2} type="number" name="motion_speed_ultra" value={motionSpeedUltra} disabled />}
                <Input label={motionSpeedLabel3} type="number" name="motion_speed_max" value={motionSpeedMax} disabled />
            </Row>
            <Row>
                <Input label="Base hour count" type="number" min="0" step="0.01" name="base_hour_count" info="The number of cumulative operating hours on the drive at the time of the Edge device installation" required disabled={isDisabled} />
            </Row>
            <HiddenInput name="id" />
            <HiddenInput name="ts" />
            {isEditable && <Button type="submit" className="btn-form-submit" disabled={!dirty || !isValid || isSubmitting || isDisabled}>Save</Button>}
            <Button type="button" className="btn-form-close" onClick={onCloseButtonClick} disabled={isSubmitting}>Close</Button>
        </Form>
    )
};
